import { keyframes } from "styled-components"
import Theme from "../../styles/Theme"

const Rotate90 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(90deg);
  }
`

const BgColor = keyframes`
  from {
    background: transparent
  }
  to {
    background-color: ${Theme.colors.background};
  }
`

export { Rotate90, BgColor }
