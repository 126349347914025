import React from "react"
import styled, { ThemeProvider } from "styled-components"

import Theme from "../styles/Theme"
import GlobalStyle from "../styles/GlobalStyle"
import Header from "./header"
import Footer from "./footer"

const StyledLayoutWrapper = styled.div`
  min-height: 100vh;
  margin: 0 auto;
`

const layout = ({ children }) => {
  return (
    <StyledLayoutWrapper>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Header />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </StyledLayoutWrapper>
  )
}

export default layout
