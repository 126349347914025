module.exports = {
  colors: {
    primary: "orange",
    background: "#ffffff",
    heroBg: "#1b1b1e",
    heroTxt: "#ffffff",
    text: "fbfffe",
  },
  breakpoints: {
    xs: "500px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  fonts: {
    primary: "montserrat, Roboto, Arial, sans-serif",
  },
  pageWidth: "100%",
  headerHeight: "3rem",
  footerHeight: "8rem",
}
