import React from "react"
import styled from "styled-components"

import Particle from "react-particles-js"
import { ImArrowRight2 } from "react-icons/im"
import { Rotate90, BgColor } from "./Animations"

import { scrollToTarget } from "../utils"

const StyledIcon = styled.svg`
  height: 32px;
  width: 32px;
  color: ${({ theme }) => theme.colors.heroTxt};
`

const StyledHero = styled.section`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.heroBg};

  canvas {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    background: transparent;

    h1,
    h2 {
      color: ${({ theme }) => theme.colors.heroTxt};
      margin: 0;
    }

    h1 {
      span {
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    button {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 2rem;
      padding: 0.5rem 1rem;
      margin: 1rem;
      background: transparent;
      border: 3px solid ${({ theme }) => theme.colors.primary};
      outline: none;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        animation: ${BgColor} 1s ease forwards;

        h2 {
          color: black;
        }
      }
      &:hover ${StyledIcon} {
        animation: ${Rotate90} 0.3s linear forwards;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

const hero = () => {
  return (
    <StyledHero id="home">
      <Particle
        params={{
          particles: {
            number: {
              value: 200,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 1,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0,
                sync: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 4,
                size_min: 0.3,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 600,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "bubble",
              },
              onclick: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 250,
                size: 0,
                duration: 2,
                opacity: 0,
              },
              repulse: {
                distance: 400,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
      <section>
        <h1>
          Hello, I'm <span>Sarun Maharjan</span>.
        </h1>
        <h1>I'm a full-stack web developer</h1>

        <button onClick={() => scrollToTarget("about")}>
          <h2 style={{ marginRight: "8px" }}>View my work</h2>
          <StyledIcon>
            <ImArrowRight2 />
          </StyledIcon>
        </button>
      </section>
    </StyledHero>
  )
}

export default hero
