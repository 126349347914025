import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Bar from "./Navigation/Bar"
import Overlay from "./Navigation/Overlay"

import { isMobile, isISSR } from "../utils"

const StyledHeader = styled.header`
  width: 100%;
  height: ${({ theme }) => theme.headerHeight};
  position: absolute;
  padding: 3rem;
  z-index: 3;

  ${({ sticky, theme }) =>
    sticky &&
    `
      position: fixed;
      top: 0;
      width: 100%;
      padding: 2rem;
      background: ${theme.colors.heroBg};
  `}

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 2rem;
  }
`

const StyledNav = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`

const header = () => {
  const {
    logo: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(imgQuery)

  const [open, setOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)

  const mobile = isMobile(windowWidth)

  useEffect(() => {
    const SSR = isISSR()
    let handleWindowSizeChange
    if (!SSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleWindowSizeChange)
    if (!mobile) setOpen(false)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])

  const [sticky, setSticky] = useState(false)

  useEffect(() => {
    const header = document.getElementById("myHeader")
    const sticky = header.offsetTop

    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    })
    return () => {
      window.removeEventListener("scroll", scrollCallBack)
    }
  }, [])

  return (
    <StyledHeader id="myHeader" sticky={sticky}>
      <StyledNav>
        <Img fixed={fixed} />
        <Bar mobile={mobile} open={open} toggler={() => setOpen(!open)} />
        {open ? <Overlay closeOverlay={() => setOpen(false)} /> : null}
      </StyledNav>
    </StyledHeader>
  )
}

const imgQuery = graphql`
  {
    logo: file(relativePath: { eq: "favicon.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default header
