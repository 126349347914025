import React from "react"
import styled from "styled-components"

import NavLinks from "./Links"

const StyledOverlayNav = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.overlay};
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 5;

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  li {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  div {
    cursor: pointer;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.navLinkColor};
    font-size: 2rem;
    line-height: 5rem;

    &:after {
      content: "";
      display: block;
      margin: auto;
      height: 3px;
      width: 0px;
      background: transparent;
      transition: width 0.5s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 20%;
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`

const navOverlay = ({ closeOverlay }) => {
  return (
    <StyledOverlayNav>
      <NavLinks closeOverlay={closeOverlay} />
    </StyledOverlayNav>
  )
}

export default navOverlay
