import React from "react"
import styled from "styled-components"

import { socialStack } from "../icons"

const StyledFooter = styled.footer`
  width: 100%;
  height: ${({ theme }) => theme.footerHeight};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    div {
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      margin: 16px;
    }
  }
`

const footer = () => {
  return (
    <StyledFooter>
      <div>
        {socialStack.map((sm, ind) => {
          return (
            <div key={ind}>
              <a href={sm.url} target="_blank">
                <sm.icon size="42px" color={sm.color} />
              </a>
            </div>
          )
        })}
      </div>
      <div>{`Sarun Maharjan © ${new Date().getFullYear()}`}</div>
    </StyledFooter>
  )
}

export default footer
