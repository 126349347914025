import Theme from "../styles/Theme"

const isMobile = windowWidth =>
  windowWidth < parseInt(Theme.breakpoints.lg.match(/\d+/gi).join(""))

const isISSR = () => typeof window === "undefined"

const scrollToTarget = el => {
  var element = document.getElementById(el)
  var headerOffset = 100
  var elementPosition = element.getBoundingClientRect().top + window.pageYOffset
  var offsetPosition = elementPosition - headerOffset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}

export { isMobile, isISSR, scrollToTarget }
