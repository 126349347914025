import { FaCss3Alt, FaHtml5, FaReact, FaNodeJs } from "react-icons/fa"
import {
  SiJavascript,
  SiTypescript,
  SiAdobexd,
  SiPostgresql,
} from "react-icons/si"
import { DiPhotoshop, DiMongodb, DiResponsive } from "react-icons/di"
import { GiSpeedometer } from "react-icons/gi"
import { RiLightbulbFlashLine } from "react-icons/ri"
import { IoIosRocket } from "react-icons/io"
import {
  AiFillGithub,
  AiFillLinkedin,
  AiFillTwitterCircle,
} from "react-icons/ai"

const techStack = [
  {
    name: "HTML5",
    icon: FaHtml5,
    color: "#E96228",
  },
  {
    name: "CSS3",
    icon: FaCss3Alt,
    color: "#254BDD",
  },
  {
    name: "JavaScript",
    icon: SiJavascript,
    color: "#EFD81D",
  },
  {
    name: "TypeScript",
    icon: SiTypescript,
    color: "#2F74C0",
  },

  {
    name: "React",
    icon: FaReact,
    color: "#0BD1F7",
  },
  {
    name: "Node.js",
    icon: FaNodeJs,
    color: "#87BF00",
  },
  {
    name: "PostgresSQL",
    icon: SiPostgresql,
    color: "#31648C",
  },
  {
    name: "MongoDB",
    icon: DiMongodb,
    color: "#4DAC3D",
  },
  {
    name: "Photoshop",
    icon: DiPhotoshop,
    color: "#001C25",
  },
  {
    name: "XD",
    icon: SiAdobexd,
    color: "#F75EEE",
  },
]

const charactersticStack = [
  {
    name: "fast",
    icon: GiSpeedometer,
    desc: "Fast load times and lag free interaction, my highest priority.",
  },
  {
    name: "responsive",
    icon: DiResponsive,
    desc: "My layouts will work on any device, big or small.",
  },
  {
    name: "intuitive",
    icon: RiLightbulbFlashLine,
    desc: "Strong preference for easy to use, intuitive UX/UI.",
  },
  {
    name: "dynamic",
    icon: IoIosRocket,
    desc: "Websites don't have to be static, I love making pages come to life.",
  },
]

const socialStack = [
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/in/sarunmrzn/",
    icon: AiFillLinkedin,
    color: "#0073AF",
  },
  {
    name: "Github",
    url: "https://github.com/sarunmrzn",
    icon: AiFillGithub,
    color: "#000000",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/sarunmrzn",
    icon: AiFillTwitterCircle,
    color: "#009DED",
  },
]

export { techStack, charactersticStack, socialStack }
