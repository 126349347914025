import React from "react"
import styled from "styled-components"

import WIP from "../images/WIP.svg"

const StyledPortfolio = styled.section`
  h1 {
    align-items: center;
    text-align: center;
  }
`
const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: auto;

  img {
    height: 300px;
    width: 100%;
  }

  h4 {
    text-align: center;
  }
`

const portfolio = () => {
  return (
    <div id="portfolio">
      <StyledPortfolio>
        <h1>Portfolio</h1>
        <StyledSection>
          <img src={WIP} />
          <h4>
            This section is still under construction, Please check back later
          </h4>
        </StyledSection>
      </StyledPortfolio>
    </div>
  )
}

export default portfolio
