import React from "react"

import Burger from "./Burger"
import NavLinks from "./Links"

const nav = ({ mobile, open, toggler }) => {
  return <>{mobile ? <Burger open={open} toggler={toggler} /> : <NavLinks />}</>
}

export default nav
