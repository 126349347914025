import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import Portfolio from "../components/portfolio"
import Contact from "../components/contact"

const index = () => {
  return (
    <div>
      <Layout>
        <SEO title={"Sarun Maharjan"} />
        <Hero />
        <About />
        <Portfolio />
        <Contact />
      </Layout>
    </div>
  )
}

export default index
