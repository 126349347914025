import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { techStack, charactersticStack } from "../icons"

import ContentWrapper from "../styles/ContentWrapper"
import { Rotate90 } from "./Animations"

const imgQuery = graphql`
  {
    profilePic: file(relativePath: { eq: "Sarun.jpg" }) {
      childImageSharp {
        fixed(height: 250, width: 280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledAbout = styled(ContentWrapper)`
  h1 {
    align-items: center;
    text-align: center;
  }
`
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const StyledIcon = styled.figure`
  width: 128px;
  height: 128px;
  background: ${({ theme }) => theme.colors.primary};
  position: relative;
  margin-top: 64px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  svg {
    color: ${({ theme }) => theme.colors.background};
  }

  ::before {
    content: "";
    position: absolute;
    top: -28.8675px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 28.8675px solid ${({ theme }) => theme.colors.primary};
  }
  ::after {
    content: "";
    position: absolute;
    bottom: -28.8675px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 28.8675px solid ${({ theme }) => theme.colors.primary};
  }
`

const AboutRowOne = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .left-content,
    .right-content {
      width: 100% !important;
    }

    .right-content {
      h2 {
        margin-top: 32px !important;
      }
    }
  }

  .left-content,
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 35%;
  }

  .left-content {
    img {
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    summary {
      .right-point {
        @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
          display: none;
        }
      }
      .left-point {
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          display: none;
        }
      }
    }
  }

  .right-content {
    display: flex;
    flex-direction: column;

    h2 {
      margin-top: 0;
    }

    section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 24px;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 12px 14px;
        align-items: center;
        height: 72px;
        width: 72px;
      }
    }
  }
`

const AboutRowTwo = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  margin-top: 32px;
  width: 80%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    div {
      width: 30% !important;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    div {
      width: 100% !important;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    margin: 16px;
    align-items: center;
    text-align: center;
  }

  h3 {
    text-transform: uppercase;
    line-height: 2rem;
    margin: 16px 0px;
  }
`

const about = () => {
  const data = useStaticQuery(imgQuery)

  return (
    <StyledAbout id="about">
      <h1>About me</h1>
      <StyledDiv>
        <AboutRowOne>
          <div className="left-content">
            <Img fixed={data.profilePic.childImageSharp.fixed} />
            <h2>Who is this guy?</h2>
            <summary>
              I'm a full-stack developer from Kathmandu, Nepal.
              <br />I merge a passion for usability and user experience with
              technical knowledge to create cool digital experiences. My
              repertoire includes programming languages and tools like{" "}
              <span className="right-point">👉</span>
              <span className="left-point">👇</span>
            </summary>
          </div>
          <div className="right-content">
            <h2>Tech Stack</h2>
            <section>
              {techStack?.map((ti, ind) => {
                return (
                  <div key={ind}>
                    <ti.icon size="64px" color={ti.color} />
                    <span style={{ marginTop: "4px" }}>{ti.name}</span>
                  </div>
                )
              })}
            </section>
          </div>
        </AboutRowOne>
        <AboutRowTwo>
          {charactersticStack?.map((sc, ind) => {
            return (
              <div key={ind}>
                <StyledIcon>
                  <sc.icon size="104px" />
                </StyledIcon>
                <h3>{sc.name}</h3>
                <span>{sc.desc}</span>
              </div>
            )
          })}
        </AboutRowTwo>
      </StyledDiv>
    </StyledAbout>
  )
}

export default about
