import React, { useState, useEffect } from "react"
import styled from "styled-components"

import ContentWrapper from "../styles/ContentWrapper"

const StyledContact = styled(ContentWrapper)`
  background-color: ${({ theme }) => theme.colors.heroBg};
  padding: 3rem;

  h1 {
    align-items: center;
    text-align: center;
    margin: 0;
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: auto;

  p {
    color: white;
  }

  form {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    button {
      outline: none;
      margin-top: 8px;
      width: 70px;
      padding: 0.5rem;
      border: 3px solid ${({ theme }) => theme.colors.background};
      border-radius: 3px;
    }
  }
`

const contact = () => {
  return (
    <StyledContact id="contact">
      <h1>Contact</h1>
      <StyledDiv>
        <p>Want to connect with me?</p>
        <form name="contact" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" name="name" placeholder="Name" />
          <input type="email" name="email" placeholder="Email" />
          <textarea name="message" rows={5} placeholder="Your Message" />
          <div data-netlify-recaptcha="true"></div>
          <button type="submit">Send</button>
        </form>
      </StyledDiv>
    </StyledContact>
  )
}

export default contact
