import React from "react"
import styled from "styled-components"

import { scrollToTarget } from "../../utils"

const StyledNav = styled.div`
  display: flex;
  width: 40%;

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  li {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  div {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;

    &:after {
      content: "";
      display: block;
      margin: auto;
      height: 3px;
      width: 0px;
      background: transparent;
      transition: width 0.5s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 100%;
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`

const navLinks = ({ closeOverlay }) => {
  return (
    <StyledNav>
      <ul>
        <li>
          <div
            onClick={() => {
              scrollToTarget("home")
              closeOverlay && closeOverlay()
            }}
          >
            home
          </div>
        </li>
        <li>
          <div
            onClick={() => {
              scrollToTarget("about")
              closeOverlay && closeOverlay()
            }}
          >
            about
          </div>
        </li>
        <li>
          <div
            onClick={() => {
              scrollToTarget("portfolio")
              closeOverlay && closeOverlay()
            }}
          >
            portfolio
          </div>
        </li>
        <li>
          <div
            onClick={() => {
              scrollToTarget("contact")
              closeOverlay && closeOverlay()
            }}
          >
            contact
          </div>
        </li>
      </ul>
    </StyledNav>
  )
}

export default navLinks
